<template>
  <div class="row justify-content-end">
      <a  @click="switchLocale('es')">
          <img class="mr-3" src="/es-flag.svg" width="30px"/>
      </a>
      <a @click="switchLocale('en')">
          <img class="mr-3" src="/en-flag.svg" width="30px" height="20px"/>
      </a>
  </div>
</template>

<script>

export default {
  data() {
    return {
        locales:['es','en'],
        //locale:""
    }
  },
  methods: {
   switchLocale: function(locale){
      if (this.$i18n.locale !== locale){
          this.$i18n.locale = locale;
      }
    },
  }
}
</script>

<style scoped>
 
</style>
