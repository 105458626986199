<template>
    <div>
        <div v-if="loading" class="spinner">
            <object data="/loading_ridid.svg" type="image/svg+xml" width="200" height="200"></object>
        </div>
        <div v-else>
             <div class="header pb-4 pt-3 pt-lg-4" >
                <span class="mask" style="background-color: #2667F7;"></span>
                <div class="row justify-content-center m-0">
                    <div class="col-lg-6 col-md-10 col-sm-11 text-center">
                            <h2 class="title1">{{ $t('title')}} {{ nombreAlojamiento }}</h2>
                            <h5 class="subtitle2" v-html="mensaje_autocheck"></h5>
                            <h5 class="subtitle2">{{ $t('subtitle3')}}</h5>
                            <h5 class="subtitle2">{{ $t('subtitle4')}}</h5>
                    </div>
                </div>
            </div> 
            <div v-cloak class="col-12">
                <div class="card card-shadow  mb-3">
                    <div class="" style=" padding: 10px">
                        <div class="text-center">
                            <div v-if="item.imageUrl==undefined">  
                                    <img src="/check.png" width="300px"> 
                            </div> 
                            <input type="file" accept="image/*" @change="onChange" class="mt-2" />
                            <div id="preview"  v-if="item.imageUrl">
                                <img  :src="item.imageUrl" style="max-width: 350px; max-height: 200px; border: 1px solid darkgrey" class="m-1"  />
                                <div class="col">
                                    <a v-if="isLoading==true && uploadPercentage!=100" class="bttn btn-blue disabled" style="width: 100%;"><font-awesome-icon icon="circle-notch" class="icon" spin/>{{ $t('updating')}}...{{uploadPercentage}}%</a>
                                    <a v-if="isLoading==true && uploadPercentage==100" class="bttn btn-blue disabled" style="width: 100%;" ><font-awesome-icon icon="circle-notch" class="icon" spin/>{{ $t('readingData')}}</a>
                                    <a v-if="isLoading==false" id="scanID"  class="bttn btn-blue" @click="uploadImage()" style="width: 100%;" ><font-awesome-icon icon="envelope" class="icon" />{{ $t('readData')}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-shadow  mb-3" >
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                <label class="form-label" for="">{{ $t('name')}}</label>
                                <input type="text" class="form-input"  v-model="currentPreGuest.name" maxlength="30">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                <label class="form-label" for="">{{ $t('surname')}}</label>
                                <input type="text" class="form-input" v-model="currentPreGuest.first_surname" maxlength="30"> <!--v-model="secondSurname" -->
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                <label class="form-label" for="">{{ $t('secondSurname')}}</label>
                                <input type="text" class="form-input" v-model="currentPreGuest.second_surname" maxlength="30"> <!--v-model="secondSurname" -->
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label">{{ $t('gender')}}</label><br>
                                    <b-form-radio-group
                                            id="btn-radios"
                                            v-model="currentPreGuest.gender"
                                            :options="optionsSex"
                                            buttons
                                            button-variant="outline-primary"
                                            name="radios-btn-default"
                                    ></b-form-radio-group>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label">{{ $t('birthDate')}}</label>
                                    <v-date-picker
                                            v-model='currentPreGuest.dob'
                                            :input-props='{class: "form-input", placeholder:"DD/MM/AAAA"}'
                                            :popover='{ placement: "bottom", visibility: "click" }'>
                                    </v-date-picker>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                <label class="form-label" for="">{{ $t('nID')}}</label>
                                <input type="text" class="form-input" v-model="currentPreGuest.idNumber" maxlength="20">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label">{{ $t('docType')}}</label><br>
                                    <b-form-radio-group
                                    id="radio-type"
                                    v-model="currentPreGuest.doc_type"
                                    :options="docTypes"
                                    buttons
                                    button-variant="outline-primary form pt-2"
                                    name="radios-btn-type"
                                    ></b-form-radio-group>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label">{{ $t('expirDate')}}</label>
                                    <v-date-picker
                                            v-model='currentPreGuest.doe'
                                            :input-props='{class: "form-input", placeholder:"DD/MM/AAAA"}'
                                            :popover='{ placement: "bottom", visibility: "click" }'>
                                    </v-date-picker>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label">{{ $t('nationality')}}</label>
                                    <v-select  label="name" :options="countries"  @input="setSelectedCountry" v-model="currentPreGuest.country.name">
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-lg-3" v-if="currentPreGuest.country.code=='ESP'">
                                <div class="form-group" >
                                        <label class="form-label">{{ $t('province')}}</label>
                                        <v-select  :filter="fuseSearch" label="name" :options="provinces"  @input="setSelectedProvince" v-model="currentPreGuest.origen.name">
                                        </v-select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="" class="form-label">{{ $t('address')}}</label>
                                    <input v-model="currentPreGuest.address" class="form-input" maxlength="500">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" >
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="card card-shadow mb-3">
                            <div class="justify-content-center" style="min-height: 200px"> 
                                <label class="firma-label">{{ $t('signHere')}}:</label>
                                    <canvas ref="signature_pad" style="width:100%"></canvas>
                                    <canvas ref="blank_pad" class="d-none"></canvas>
                                    <input type="hidden" name="signature" ref="signature_value">
                                    <img src="/firmar_aqui.svg" alt="Firma aqui" ref="imagenfirma" class="d-none">
                             
                            </div>
                            <div class="text-center">
                                <a class="bttn btn-red mb-2 mr-2" style="width: 105px" ref="borrar_firma">{{ $t('clear')}}</a>
                                <a ref="undo_signature" class="bttn btn-grey mb-2">{{ $t('undo')}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                        <div class="card card-shadow mb-3"  style="height: 100%;">
                            <div class="card-body">
                                <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group text-left">
                                                <label class="form-label " for="">{{ $t('phone')}}</label>
                                                <vue-tel-input class="phone-input" v-bind="bindProps" v-model="currentPreGuest.phone" ></vue-tel-input>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group text-left">
                                                <label class="form-label" for="">{{ $t('email')}}</label>
                                                <input type="email" class="form-input"  v-model="currentPreGuest.email" maxlength="64">
                                            </div>
                                        </div>
                                    <div class="col-12">
                                            <div class="row m-0">
                                                <label >
                                                <input type="checkbox" v-model="currentPreGuest.checked">
                                                <span class="checkmark"></span>
                                                {{ $t('iReadIt')}} <a @click="getTermsAndCondition()" class="text-primary" id="privacy">{{ $t('policyAndTerms')}}</a>, {{ $t('iAcceptIt')}}</label>
                                                <p style="font-size: 12px;text-align:justify">{{ $t('responsible')}}: {{ responsable }}; {{ $t('purpose')}}</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="text-center" >
                                    <a v-if="sendingData==true" class=" bttn btn-blue disabled" style="width: 100%;"><font-awesome-icon icon="circle-notch" class="icon" spin/>{{ $t('sendData')}}</a>
                                    <a v-else class=" bttn btn-blue" @click="sendGuestData()" style="width: 100%; "><font-awesome-icon icon="envelope" class="icon"/>{{ $t('sendData')}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="modalPrivacy" size="xl" hide-footer centered scrollable>
                  <template #modal-header>
                        <a class="x" @click="$bvModal.hide('modalPrivacy')"><font-awesome-icon icon="times" /></a> 
                    </template>
                  <div class="" id="terminos"><span v-html="termsAndCondition"></span>
                  </div>
                  <div class="text-center">
                    <a class="bttn btn-grey" @click="$bvModal.hide('modalPrivacy')">{{ $t('close')}}</a>
                  </div>
            </b-modal>
            <b-modal id="modalObvio" size="sm" hide-footer centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('modalObvio')"><font-awesome-icon icon="times" /></a> 
                    <div class="text-center">
                        <h3 class="bold text-red m-0">{{ $t('noRead')}}</h3>
                    </div>
                </template>
                <div class="mr-2 ml-2 text-center">
                    <p style="font-size: 20px;"><span style="font-weight: bold;text-align:justify">{{ $t('photoAgain')}}</span> {{ $t('howPhoto')}}<span style="font-weight: bold;"> {{ $t('orManually')}}.</span></p>
                    <img src="/check.png" width="250px" alt="" > 
                    <div class="text-center mt-3">
                        <a class="bttn btn-grey" @click="$bvModal.hide('modalObvio')">{{ $t('close')}}</a>
                    </div>
                </div> 
           </b-modal>
        </div>
    </div>
</template>

<script>
    
import axios from "axios"
//import VueNumeric from 'vue-numeric/src/vue-numeric'
//import vSelect from 'vue-select'
import moment from 'moment'
//import DatePicker from 'v-calendar/lib/components/date-picker.umd'

//import Stripe from 'stripe';
import * as imageConversion from 'image-conversion';
import Fuse from 'fuse.js'
import SignaturePad from 'signature_pad'
import $ from 'jquery'
import vSelect from 'vue-select'


export default {
  name: 'AutoGuest',
  components: {
    'v-select':vSelect,
    //'v-date-picker':DatePicker,
    //'vue-numeric':VueNumeric,
  },
  data() {
    return{
        item:{
          //...
          image : null,
          imageUrl: null
        },
        bindProps: { //para tel-input
              mode: "international",
              //placeholder: "Enter a phone number",
              required: true,
              enabledFlags: true,
              autocomplete: "off",
              name: "telephone",
              maxLen: 25,
              inputOptions: {
                showDialCode: true
              },
          },
        loading:true,
        mensaje_autocheck:undefined,
        responsable:undefined,
        logo:undefined,
        nombreAlojamiento:undefined,
        countries: [],
        provinces:[],
        docTypes: [
            { text: 'DNI', value: 'D' },
            { text: 'NIE', value: 'N' },
            { text: 'Pasp', value: 'P' },
            { text: 'ID', value: 'I' },
            { text: 'C', value: 'C' },
            { text: 'X', value: 'X' },
            ],
        optionsSex: [
              { text: 'Masculino', value: 'M' },
              { text: 'Femenino', value: 'F' },
            ],
         optionsSexEn: [
              { text: 'Male', value: 'M' },
              { text: 'Female', value: 'F' },
            ],

        currentPreGuest:{
            "name": '',
            "first_surname":'',
            "second_surname":'',
            "gender": "",
            "dob": '',
            "idNumber": '',
            "doc_type": "",
            "country": {'name':'','code':''},
            "doe": '',
            "address":'',
            'selectedRoom':{"name":"","safeURL":""},
            'phone':"",
            'email':'',
            'signature':'',
            'origen':{'name':'','code':''},
            'checked':false,
        },
        camera: false,
        isLoading: false,
        mainUrl: 'http://localhost:8000',
        roomsServer:[],
        termsAndCondition:"",
        contract:"",
        uploadPercentage:0,
        sendingData:false,
        language: "",

    }
  },
  methods: {
        getTermsAndCondition: function(){
            let vm=this
            axios.post('/api/new_guest/get_terms_public',{
                "urlReserva":this.SafeURLReserva})
            .then(function(res) { //poner solo esto si es Ok
                let status=res.data.resultado
                if (status=="Ok"){
                    vm.termsAndCondition=res.data.terms
                    vm.$bvModal.show("modalPrivacy")
                }
                else{
                    vm.isLoading = false;
                    vm.popToast("danger", "", "Error")
                }

            }).catch(function(err) {
                console.log(err)
                vm.isLoading = false;
                vm.popToast("danger", "Error", "Error")
            })
        },

        sendGuestData: function(){
            this.sendingData=true
            var signaturePad=this.$refs.signature_pad
            var blankPad=this.$refs.blank_pad
            this.currentPreGuest.signature=signaturePad.toDataURL()
            this.blankPad=blankPad.toDataURL()
            if (this.currentPreGuest.signature==this.blankPad){
                if (this.language=="EN"){
                    this.sendingData=false
                    return(this.popToast("danger", "Please, sign in the signature pad", "Error"))
                }
                else{
                    this.sendingData=false
                    return(this.popToast("danger", "Por favor, firme en el panel de firma", "Error"))
                }
            }

            axios.post('/api/auto_check_in/add', {
                            'DocNumber' : this.currentPreGuest.idNumber,
                            'DocType': this.currentPreGuest.doc_type,
                            'ExpirationDate' : moment(this.currentPreGuest.doe).format('YYYY-MM-DD'),
                            'FirstSurname' : this.currentPreGuest.first_surname,
                            'SecondSurname' : this.currentPreGuest.second_surname,
                            'Name' : this.currentPreGuest.name,
                            'Sex' : this.currentPreGuest.gender,
                            'BirthDate' : moment(this.currentPreGuest.dob).format('YYYY-MM-DD'),
                            'NationalityCountry' : this.currentPreGuest.country.code,
                            'Signature' : this.currentPreGuest.signature,
                            'GuestEmail' : this.currentPreGuest.email,
                            'RoomSecretID': this.currentPreGuest.selectedRoom.safeURL,
                            'Origen' : this.currentPreGuest.origen.code,
                            'Address' : this.currentPreGuest.address,
                            'PhoneNumber' : this.currentPreGuest.phone,
                            'bookingURL': this.SafeURLReserva,
                            'preCheckURL':this.SafeURLGuest,
                            'checked':this.currentPreGuest.checked
                        })

                    .then((response) => {
                        //console.log(response)
                        this.status=response.data
                        if (this.status.resultado == "Ok"){
                            this.popToast("success", this.status.mensaje, "Ok")
                            this.currentPreGuest={
                                "name": '',
                                //"surnames": '',
                                "first_surname":'',
                                "second_surname":'',
                                "gender": "",
                                "dob": '',
                                "idNumber": '',
                                "doc_type": "",
                                "country": {'name':'','code':''},
                                "doe": '',
                                "address":'',
                                'selectedRoom':{"name":"","safeURL":""},
                                'phone':"",
                                'email':'',
                                'signature':'',
                                'origen':{'name':'','code':''},
                                'checked':false,
                            }
                            let wait = (milliseconds) => {
                                return new Promise(resolve => setTimeout(resolve, milliseconds))
                            };
                            wait(2000).then(() =>{
                                //var url=(window.location.href).split("/")[0]
                                console.log("redirigiendo")
                                //window.location = url;
                                this.$router.push({ name: 'AutoList', params:{SafeURLReserva:this.SafeURLReserva}})
                                
                                })
                        }
                        else{
                            
                            this.popToast("danger", this.status.mensaje, "Error")
                            this.sendingData=false
                        }
                        }).catch((error) => {
                        console.log(error);
                        this.sendingData=false
                    });
            },

        setSelectedCountry: function(val){
            this.currentPreGuest.country.code=val.code
            this.currentPreGuest.country.name=val.name
            if (val.code!="ESP"){
                this.currentPreGuest.origen.code=val.code
                this.currentPreGuest.origen.name=val.name
            }
        },
        setSelectedProvince: function(val){
                this.currentPreGuest.origen.code=val.code
                this.currentPreGuest.origen.name=val.name
            },
/*

        setCurrentRoom: function(value){
            this.currentPreGuest.selectedRoom.safeURL = value.SafeURL
            this.currentPreGuest.selectedRoom.name=value.Identificador
            //console.log(this.currentSelectedRoom)
        },

        reset() {
            location.reload();
        },

        getRooms: function(){
            axios.get('/api/rooms/get_rooms')
                .then((response) => {
                    this.status = response.data
                    if (this.status.resultado == "Ok") {
                        //console.log("Habitaciones:",this.status.rooms)
                        this.roomsServer=this.status.rooms
                    } else {
                        console.log("Error al obtener listado de apartamentos")
                    }
                }).catch((error) => {
                    console.log(error);
                });
        },
 */
        getProvincesList: function(){
                axios.get('/api/provinces/get')
                    .then((result) => {
                        if (result.data){
                            this.provinces=result.data
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", "Error al obtener provincias", "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                });
        },
        onChange(e) {
            this.file = e.target.files[0]
            //console.log("file original", this.file)
            imageConversion.compress(this.file,{
                quality: 0.8,
                type: "image/jpeg",
                orientation:1,
              }).then(res=>{
                    //console.log("file compressed",res);
                    this.file=res
                    //console.log(res.size)
                })
              //this.image = file
            this.item.imageUrl = URL.createObjectURL(this.file)
        },

        uploadImage() {
            let btnScan=document.getElementById("scanID")
            this.isLoading = true;
            btnScan.disabled=true;
            let vm=this
            const URL = '/api/new_guest/read_mrz_external';
            let data = new FormData();
            data.append('name', 'my-picture');
            data.append('file', this.file);
            data.append('url',this.SafeURLReserva)

            let config = {
                    header : {
                        'Content-Type' : 'image/jpeg'
                    },
                    onUploadProgress: function( progressEvent ) {
                        this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                    }.bind(this)
                };

            axios.post(
              URL,
              data,
              config

            ).then(function(res) {
                console.log(res)
                var parsed_data = res.data.data
                vm.mensaje=res.data.mensaje
                if (res.data.resultado=="Ok") {
                    if (parsed_data.nationality != "ESP") {
                        vm.currentPreGuest.origen.code = parsed_data.nationality
                    }
                    vm.currentPreGuest.name = parsed_data.name
                    vm.currentPreGuest.surnames = parsed_data.first_surname + " " + parsed_data.second_surname
                    vm.currentPreGuest.first_surname = parsed_data.first_surname
                    vm.currentPreGuest.second_surname = parsed_data.second_surname
                    vm.currentPreGuest.gender = parsed_data.sex
                    //vm.currentPreGuest.dob = parsed_data.date_of_birth
                    vm.currentPreGuest.dob = new Date(moment(parsed_data.date_of_birth, "YYYYMMDD"))
                    vm.currentPreGuest.idNumber = parsed_data.doc_number
                    vm.currentPreGuest.doc_type = parsed_data.doc_type
                    vm.currentPreGuest.country.code = parsed_data.nationality
                    vm.currentPreGuest.country.name = parsed_data.country_name
                    //vm.currentPreGuest.doe = parsed_data.expiration_date
                    vm.currentPreGuest.doe =new Date (moment(parsed_data.expiration_date, "YYMMDD"))
                    //vm.currentGuest.address=parsed_data.address
                    vm.currentPreGuest.address=parsed_data.address
                    if (parsed_data.province.code!="-"){
                        vm.currentPreGuest.origen=parsed_data.province
                    }
                    vm.isLoading = false;
                    vm.uploadPercentage=0
                    btnScan.disabled=false;
                    vm.popToast("success", vm.mensaje, "Correcto")
                }
                
                else if(res.data.resultado=="Unavailable"){
                    vm.isLoading = false;
                    btnScan.disabled=false;
                    vm.uploadPercentage=0
                    vm.popToast("danger", "Servicio de escaneo no disponible para esta reserva", "Error")
                }
                else{
                    vm.isLoading = false;
                    btnScan.disabled=false;
                    vm.uploadPercentage=0
                    vm.$bvModal.show("modalObvio")
                 //   vm.popToast("danger", vm.mensaje, "Error")
                    //vm.closeCamera()
                }

            }).catch(function(err) {
                console.log(err)
                vm.isLoading = false;
                btnScan.disabled=false;
                vm.uploadPercentage=0;
                vm.popToast("danger", vm.mensaje, "Error");
               // vm.$bvModal.show("modalObvio")

                //vm.closeCamera()
            })
          },


        getCountriesList: function(){
                axios.get('/api/countries/get')
                    .then((result) => {
                        if (result.data){
                            this.countries=result.data
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", "Error al obtener paises", "Error")
                        }
            }).catch((error) => {
                console.log(error);
            });
        },
        fuseSearch(options, search) {
            const fuse = new Fuse(options, {
                keys: ["name"],
                shouldSort: true
            });
            return search.length
                ? fuse.search(search).map(({ item }) => item)
                : fuse.list;
        },
        popToast(type, message) {
        this.$bvToast.toast(message, {
            variant: type,
            solid: true,
            noCloseButton: true,
            toaster: 'b-toaster-top-right',                
        })
      },
      get_guest_precheck_data: function(){
        axios.post('/api/auto_check_in/guest/get_data', {
                        "SafeURLReserva": this.SafeURLReserva,
                        "SafeURLAutoCheckIn":this.SafeURLGuest
                    })
                    .then((result) => {
                        console.log("miau",result.data)
                        if (result.data.resultado === "Ok"){
                            this.nombreAlojamiento=result.data.nombreAlojamiento
                            this.mensaje_autocheck=result.data.mensaje_precheck
                            this.responsable=result.data.responsable
                            this.logo=result.data.logo
                            this.loading=false
                            this.setupCanvas()
                        }
                        else if (result.data.resultado === "Redirect"){
                            this.$router.push({ name: 'AutoList', params:{SafeURLReserva:this.SafeURLReserva}})
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                        else if (result.data.resultado === "Error"){
                            this.$router.push({ name: 'AutoList', params:{SafeURLReserva:this.SafeURLReserva}})
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
      },
      
      sleep: function(milliseconds){
                return new Promise(resolve => setTimeout(resolve, milliseconds))
                },

      setupCanvas: function(){
          let vm=this
          this.sleep(500).then(() => {
            var canvas = vm.$refs.signature_pad
            var canvasBlank=vm.$refs.blank_pad
            
            var parentWidth = $(canvas).parent().outerWidth();
            var parentHeight = $(canvas).parent().outerHeight();
            //var scale = window.devicePixelRatio
            //scale = 1
            //console.log(canvas, canvasBlank, scale, parentWidth, parentHeight)

            //canvas.getContext("2d").scale(scale, scale);
            canvas.width = parentWidth * 0.98// * scale;
            canvas.height = parentHeight// * scale;

            //canvasBlank.getContext("2d").scale(scale, scale);
            canvasBlank.width = parentWidth * 0.98// * scale;
            canvasBlank.height = parentHeight// * scale;
            
            
            

            vm.signaturePad = new SignaturePad(vm.$refs.signature_pad, {
                    minWidth: 2,
                    maxWidth: 4,
                    backgroundColor: 'rgb(255, 255, 255)'
                    });
            vm.signaturePadBlank = new SignaturePad(vm.$refs.blank_pad, {
                    minWidth: 2,
                    maxWidth: 4,
                    backgroundColor: 'rgb(255, 255, 255)'
                    });
            vm.$refs.undo_signature.addEventListener('click', function () {
                    var data = vm.signaturePad.toData();
                    if (data) {
                        data.pop(); // remove the last dot or line
                        vm.signaturePad.fromData(data);
                    }
                });

            vm.$refs.borrar_firma.addEventListener('click', function () {
                vm.signaturePad.clear()
            });
                })
          
      }
    },

    
    mounted: function () {
        let vm=this;
        vm.camera=true;
        this.SafeURLGuest=this.$route.params.SafeURLGuest
        this.SafeURLReserva=this.$route.params.SafeURLReserva
        this.get_guest_precheck_data()
        this.getCountriesList()
        this.getProvincesList()
        //this.loading=false        
    }
}
</script>
<style>
.text-red{
    color:red;
}
.form-label {
  font-size: 18px;
  color: #2667F7;
  /*background-color: white;*/
}
.active{
  background-color: #2667F7!important;
  color: white!important;
}
input{
    outline: transparent;
}
.form-input{
    border: 1px solid #2667F7;
    background-color: white;
    width: 100%;
    padding: 7px;
    font-size: 18px;
    box-shadow:0px 10px 10px -8px rgba(0,0,0,0.1);
    border-radius: 7px!important;
    height: 43px!important;
    color: #000000!important;
}

.form-input:hover{
   border: 2px solid #2667F7!important;
   padding: 6px;
}

.form-textarea{
    border: 1px solid #2667F7;
    background-color: white;
    width: 100%;
    min-height: 43px;
    padding: 7px;
    font-size: 18px;
    box-shadow:0px 10px 10px -8px rgba(0,0,0,0.1);
    border-radius: 7px!important;
   outline: none!important;
}


.form-textarea:hover{
   border: 2px solid #2667F7!important;
   border-radius: 7px;
    outline: none!important;
    padding: 6px;
}


.form-date{
    border: 1px solid #2667F7;
    background-color: white;
    width: 100%;
    padding: 7px;
    font-size: 18px;
    box-shadow:0px 10px 10px -8px rgba(0,0,0,0.1);
    border-radius: 7px 0 0 7px!important;
    height: 43px!important;
    color: #000000;
}

.form-date:hover{
   border: 2px solid #2667F7;
   border-radius: 7px;
   outline: none!important;
   padding: 6px;
}
.form-date:focus{
   border: 2px solid #2667F7;
   border-radius: 7px;
   outline: none!important;
   padding: 6px;
}


.btn-outline-primary{
     border: 1px solid #2667F7!important;
     color:#2667F7;
     height:43px;
     padding-top:0.5rem !important;
}
.btn-outline-primary:hover{
     color:white!important;
}
/* dropdown */

.vs__dropdown-toggle{
    border: 1px solid #2667F7!important;
    background-color: #D0E1FE!important;
    color: #FFFFFF!important;
    width: 100% !important;
    padding: 7px !important;
    font-size: 17px !important;
    box-shadow: 0px 10px 10px -8px rgba(0,0,0,0.1) !important;
    border-radius: 7px !important;
    height: 43px!important;
    z-index: 2;
}
.vs__dropdown-toggle:hover{
    border: 2px solid #2667F7!important;
    padding: 6px!important;
}

.vs__actions{
    padding: 5px!important;
}
.vs__open-indicator{
        fill: #2667F7!important;
}
.vs__search{
    margin-top: 0!important;
    padding: 0!important;
    color: #000!important;
    border: none!important;
      font-size: 17px !important;
}
.vs__selected-options{
    overflow:hidden!important;
    white-space: nowrap;
}
.vs__selected{
    margin: 0!important;
    padding: 0!important;
    color: #000!important;
    border: none!important;
}

.vs__clear{
    display: none;
}

/*vue-phone field*/
.vue-tel-input{
  border: transparent!important;
  box-shadow: none!important;
}

.vue-tel-input:hover .vti__dropdown{
    /*border-radius: 7px 0 0 7px!important;*/
    border-top: 2px solid #2667F7!important;
    border-bottom: 2px solid #2667F7!important;
    border-left: 2px solid #2667F7!important;
    padding:5px!important;
}
.vue-tel-input:hover .vti__input{
    border-top: 2px solid #2667F7!important;
    border-bottom: 2px solid #2667F7!important;
    border-right: 2px solid #2667F7!important;
    padding:5px!important;
}


/*sin presionar*/
.vti__dropdown{
  border-radius: 7px 0 0 7px!important;
  border: 1px solid #2667F7!important;
  border-right: 0px;
  height: 43px!important;
padding: 6px;
}
.vti__dropdown:hover{
    background-color: white ;
}
.vti__input{
  border-top: 1px solid #2667F7!important;
  border-bottom: 1px solid #2667F7!important;
  border-right: 1px solid #2667F7!important;
  border-radius:0px 7px 7px 0!important;
  padding:4px!important;
  height: 43px!important;
}

#signature-pad{
    background-color: white ;
    width: 98%; 
    height: 100%; 
   /* box-shadow: 0 0 8px 2px rgba(0,0,0,0.08);*/
    border: 1px solid #2667F7;
    border-radius: 7px;
}

#blank-pad {
    background-color: white ;
    width: 98%; 
    height: 100%; 
    box-shadow: 0 0 8px 2px rgba(0,0,0,0.08); 
    display: none;
}
.firma-label {
    font-size: 20px;
    position: absolute;
 
    padding: 5px;
    color: #2667F7;
    background-color: white;
  }

</style>
