
import Vue from 'vue'
//import _ from 'underscore';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from "axios";
import { library } from '@fortawesome/fontawesome-svg-core'
//import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vSelect from 'vue-select'
import VCalendar from 'v-calendar';
import 'vue-select/dist/vue-select.css';
import VueNumeric from 'vue-numeric/src/vue-numeric'
import VueTelInput from 'vue-tel-input'
import i18n from './i18n'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL


Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueNumeric)
Vue.use(VueTelInput) // Define default global options here (optional)
Vue.use(VCalendar)
library.add(far)
library.add(fas)
Vue.prototype.$http = axios;

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
