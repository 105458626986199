<template>
  <div id="">
    <nav class="navbar navbar-light bg-white card-shadow p-2" style="z-index: 3">
                <div class="col-2 text-left">
                    <a class="navbar-brand d-none d-md-block" href="https://ridid.me/">
                        <img src="/logo266.svg"  height="40px" id="logoridid">
                    </a>
                    <a class="navbar-brand d-block d-md-none" href="https://ridid.me/" >
                        <img src="/r266.svg" height="40px" id="logoridid" >
                    </a>
                </div>
                <div class="col text-center" >
                    <img alt="Logo hospedaje" :src="logo" height="50" style="max-width: 160px;" id="logonav">
                </div>
                <div class="col-2 text-right" >
                    <LanguageSwitcher/>
                </div>

        </nav>


    <div class="row justify-content-center">
      <p style="position:absolute; bottom: 0; margin:40px 40px 0 40px; text-align:center; color:grey">Si la página aparece en blanco recargue o cambie de navegador.  
      <a href="https://www.microsoft.com/en-us/edge" target="_blank">Descargue aquí Microsoft Edge</a></p>
    </div>
   <router-view />
  </div>
</template>
<script>
    
import axios from "axios"
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
//import moment from 'moment'

export default {
    name: 'PrecheckApp',
    components:{
        LanguageSwitcher
    },
    data() {
        return {
            loading:true,
            logo:undefined,
            //language:"ES"
           }
    },
    methods: {
       
        get_logo: function(){
            axios.post('/api/auto_check_in/get_logo', {
                        "SafeURLReserva": this.SafeURLReserva,
                    })
                    .then((result) => {
                        //console.log("guau",result.data)
                        if (result.data.resultado === "Ok"){
                            this.logo=result.data.logo
                            this.loading=false
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.$router.push({ name: 'Error'})
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
        },
    },
    mounted: function () {
      this.SafeURLReserva=this.$route.params.SafeURLReserva
        //this.SafeURLReserva=(this.$router.currentRoute.path).split("/")[((this.$router.currentRoute.path).split("/")).length-1]
      this.get_logo()
    }
}
</script>
<style>
 
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");


html{
    font-family: 'Quicksand', sans-serif;
}
body{
    font-family: 'Quicksand', sans-serif!important;
    background-color: #FFFFFF;
    color:#000;
    overflow-y:auto;
}
.main-content{
  position: relative;
}


p{
    font-size: 18px;
    color:#000;
}

a{
    cursor: pointer;
    text-decoration: none;
    color:black;
}
a:hover{
    text-decoration: none!important;
}

[v-cloak] {
  display: none;
}
.jus{
    text-align: justify;
}

.hidey{
    overflow-y: hidden;
}
.bold{
    font-weight: bold;
}
/*text-color*/

.text-black{
  color:black!important;
  font-weight:  400;
}

.text-white{
    color: white;
}

.text-grey{
    color: grey;
}



/*cards */
.card{
  border-radius:7px!important;
}
.shade{
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important;
}
.card-shadow{
    -webkit-box-shadow: 0 0 8px 2px rgba(0,0,0,0.08);
    -moz-box-shadow: 0 0 8px 2px rgba(0,0,0,0.08);
    box-shadow: 0 0 8px 2px rgba(0,0,0,0.08);
}
.card-head {
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    background-color: #fff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
    border-radius:7px 7px 0 0;
}
/*mask*/
.header{
  position: relative;
}
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .15s ease;
    background-color: #2667F7;
}
.title1{
    color:white;
    font-weight:bold;
}
.subtitle{
    color:white;
    /*text-align:justify;*/
}
.subtitle2{
    color:white;
    /*text-align:justify;*/
}
.entry{
    margin-top:-60px; 
    overflow-x: hidden; 
    padding: 15px
}

/* ///// spinner square////////// */
.spinner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 90vh;
}

/* /////pointer////// */
.pointer {
    cursor: pointer;
}

/* ///// modals //// */

.x{
  color:grey!important;
  font-size: 20px;
  padding: 0 15px;
/*padding: 0 10px 10px 10px;*/

}

.x:hover{
  color:#FF3B30!important;
}

.blue{
    color: #2667F7!important;
}
/* ////// toast ///// */
.popover{
    z-index:20!important;
}
.toast{
  border: #e2dfdd solid 1px!important;
  border-radius: 7px!important;
}

.b-toast{
  text-align: center!important;
    opacity: 0.9;
}
.toast-header {
    padding: 0 !important;
    margin: 0 !important;
    width: 0px !important;
    text-align: center;
}

.toast-body{
  padding: 10px!important;
  font-size:18px!important;
}

/*   input*/
input[type="date"]{
    cursor: text;
    outline: none!important;
}
input[type="date"]::-webkit-clear-button {
    display: none;
}

input[type="date"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}
input[type="date"]::-ms-clear {
    display: none;
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background:none;
  z-index: 1;
}

input[type="date"]:before {
    color: #2667F7;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f073';
    /* This is the calendar icon in FontAwesome*/
    width: 15px;
    height: 20px;
    position: absolute;
    top: 7px;
    right: 15px;
    padding: 1px;
    cursor: pointer!important;
}
input[type="date"]:before:hover {
        max-height: 43px!important;

}
/* number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox*/
input[type=number] {
  -moz-appearance:textfield;
}
input[type=checkbox] {
    height: 20px;
    width: 20px;
}

/*  buttons */
/*
.active{
  background-color: #2667F7!important;
  color: white!important;
}*/

.disabled{
  pointer-events:none!important;
  opacity:0.6;
}
.bttn:disabled{
    pointer-events:none!important;
}


.bttn{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.55rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    transition: none;
    font-weight:600;
}

.btn-blue{
    border: 1px solid #2667F7;
    background-color: #2667F7;
    color: white;
    height: 43px;
}

.btn-blue:hover{
    border: 1px solid #2667F7;
    background-color: white;
    color: #2667F7;
    height: 43px;
    transform: translateY(0);
}

.btn-outline-blue{
    border: 1px solid #2667F7;
    background-color: white;
    color: #2667F7;
    height: 43px;
    transform: 0;
}

.btn-outline-blue:hover{
    border: 1px solid #2667F7;
    background-color: #2667F7;
    color: white;
    height: 43px;
}

.btn-indigo{
    border: 1px solid  #83b3f7;
    background-color: #83b3f7;
    color: white;
    height: 43px;
}

.btn-indigo:hover{
    border: 1px solid #83b3f7;
    background-color: white;
    color: #83b3f7;/*A4ECFA*/
    transform: translateY(0);
    height: 43px;
}


.btn-red{
    border: 1px solid #FF3B30;
    background-color: #FF3B30 ;
    color: white;
    height: 43px;

}
.btn-red:hover{
    border: 1px solid #FF3B30;
    background-color: white;
    color: #FF3B30;
    transform: translateY(0);
}


.btn-grey{
    border: #9BA0AA 1px solid;
    background-color: #9BA0AA;
    color: white;
    height: 43px;
}

.btn-grey:hover{
    border: #9BA0AA 1px solid;
    background-color: white;
    color: #9BA0AA;
    transform: translateY(0);

}


/*navbar*/
.nav-logo{
    width: 140px;
}

/*icons */
.icon{
    margin-right:7px;
}
.icon-l{
    margin-left:7px;
}






</style>
