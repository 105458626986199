<template >
    <div>
        <div  v-if="loading" class="spinner">
            <object data="/loading_ridid.svg" type="image/svg+xml" width="200" height="200"></object>
        </div>
        <div v-else>
            <div class="header pb-4 pt-3 pt-lg-4" >
                <span class="mask" style="background-color: #2667F7;"></span>
                <div class="row justify-content-center m-0">
                    <div class="col-lg-6 col-md-10 col-sm-11 text-center">
                        <div>
                            <h2 class="title1">{{ $t('title')}} {{ nombreAlojamiento }}</h2>
                            <h5 class="subtitle" v-html="mensaje_autocheck"></h5>
                            <h5 class="subtitle">{{ $t('subtitle1')}}</h5>
                            <h5 class="subtitle">{{ $t('subtitle2')}}</h5>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="row justify-content-center m-0" >
                <div class="col-10 mt-2 mb-4">
                    <div v-for="guest in ocupantes" v-bind:key="guest.SafeURL" @click="redirect_to_guest_form(guest)"  class=" mb-2"> <!--:to="{name: 'AutoGuest', params:{SafeURLReserva:SafeURLReserva ,SafeURLGuest:guest.SafeURL}}"-->
                        <div v-if="guest.Nombre == ' '" class="auto-btn">
                            <div>
                                <h2>{{ $t('addData')}}</h2> 
                            </div>
                        </div>
                        <div v-else class="form-filled">
                            <div class="row ">
                                <div class="col-lg-3 col-md-2 col-sm-12 text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="#20CB76" d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z"/></svg>
                                </div>
                                <div class="col text-center">
                                    <h3 class="pt-md-2">
                                        <span>
                                            {{ $t('guest')}} {{ guest.Id }}: 
                                        </span>
                                        {{ guest.Nombre }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    
import axios from "axios"

//import moment from 'moment'

export default {
    name: 'AutoList',
    props:{
        language:String
    },
    data() {
        return {
            loading:true,
            ocupantes:[],
            nombreAlojamiento:undefined,
            mensaje_autocheck:"",
            
           }
    },
    methods: {
        redirect_to_guest_form: function(guest){
            if (guest.Nombre!=' '){
                console.log(guest)
                return(this.popToast("danger", "Este huesped ya ha rellenado sus datos, si no quedan tarjetas para introducir datos de más huéspedes, contacte con el establecimiento para que añada más.", "Error"))
            }
            else{
                this.$router.push({ name: 'AutoGuest', params:{SafeURLReserva:this.SafeURLReserva, SafeURLGuest:guest.SafeURL}})
            }

        },
        popToast(type, message) {
            this.$bvToast.toast(message, {
                variant: type,
                solid: true,
                noCloseButton: true,
                toaster: 'b-toaster-top-right',                
            })
        },
        get_precheck_data: function(){
            axios.post('/api/auto_check_in/get_data', {
                        "SafeURLReserva": this.SafeURLReserva,
                    })
                    .then((result) => {
                        console.log("miau",result.data)
                        if (result.data.resultado === "Ok"){
                            this.nombreAlojamiento=result.data.nombreAlojamiento
                            this.ocupantes=result.data.ocupantes
                            this.loading=false
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
        },
    },
    mounted: function () {        
        this.SafeURLReserva=this.$route.params.SafeURLReserva
        console.log(this.SafeURLReserva)
        this.get_precheck_data()
    }
}
</script>
<style scoped>

.auto-btn{
    padding: 1.25rem;
    text-align:center;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 25px;
    background-color:#cde2ff;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 8px 2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 0 8px 2px rgba(0,0,0,0.15);
    box-shadow: 0 0 8px 2px rgba(0,0,0,0.15);
}
.auto-btn:hover{
    background-color: white;
   /* color: #9bc3fc;*/
}
.form-filled{
    padding: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 25px;
    background-color:white;
    background-clip: border-box;
    -webkit-box-shadow: 0 0 8px 2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 0 8px 2px rgba(0,0,0,0.15);
    box-shadow: 0 0 8px 2px rgba(0,0,0,0.15);
}
</style>