import VueRouter from 'vue-router'
import AutoGuest from '../views/AutoGuest.vue'
import AutoList from '../views/AutoList.vue'
//Vue.use(VueRouter)

const routes = [
  {
    path: '/:SafeURLReserva/:SafeURLGuest',
    component: AutoGuest,
    name: 'AutoGuest'
  },
  {
    path: '/:SafeURLReserva',
    component: AutoList,
    name: 'AutoList'
  },
  {
    path: '*',
    name: "Error",
    component: () => import("../views/errors/Error404.vue")
  }
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
